<template>
  <!-- <v-app id="resetpassword-app">
        <v-main> -->
  <v-container fill-height>
    <v-dialog
      v-model="success"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('reset_pw.title') }}
        </v-card-title>
        <v-card-text> {{ $t('reset_pw.subtitle.success') }} </v-card-text>
        <v-card-actions>
          <v-btn
            color="white"
            :to="{ name: 'Login' }"
          >
            <v-icon
              left
              dark
            >
              mdi-keyboard-backspace
            </v-icon> {{ $t('reset_pw.buttons.login') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      :loading="requestLoading"
      class="mx-auto my-12"
      max-width="550"
      min-width="550"
    >
      <v-card-title>{{ $t('reset_pw.title') }}</v-card-title>
      <v-card-subtitle>{{ $t('reset_pw.subtitle.default') }}</v-card-subtitle>
      <v-form @submit.prevent="onSubmit">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                v-model="form.newPassword"
                :label="$t('reset_pw.form.new_pw')"
                :error="$v.form.newPassword.$error"
                :error-messages="$v.form.newPassword.$error
                  ? (!$v.form.newPassword.required
                    ? $t('validate.required', { field: $t('reset_pw.form.new_pw') })
                    : (!$v.form.newPassword.minLength
                      ? $t('validate.minLength', { length: 8 })
                      : (!$v.form.newPassword.notWeak
                        ? $t('reset_pw.strength.weakNotAllowed')
                        : '')))
                  : ''"
              />
              <div v-if="form.newPassword">
                <v-alert
                  dense
                  text
                  :color="passwordStrength === 'weak' ? 'error' : (passwordStrength === 'strong' ? 'success' : 'warning')"
                >
                  {{ $t('reset_pw.strength.label') }}: <b>{{ $t('reset_pw.strength.level')[passwordStrength] }}</b>
                  <div>
                    <v-icon>
                      {{ passwordAtLeastOneUpperCase ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.atLeastOneUpperCase') }}
                  </div>
                  <div>
                    <v-icon>
                      {{ passwordAtLeastOneLowerCase ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.atLeastOneLowerCase') }}
                  </div>
                  <div>
                    <v-icon>
                      {{ passwordAtLeastOneNumber ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.atLeastOneNumber') }}
                  </div>
                  <div>
                    <v-icon>
                      {{ passwordAtLeastOneSpecialCharacter ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.atLeastOneSpecialCharacter') }}
                  </div>
                  <div>
                    <v-icon>
                      {{ passwordLongEnough ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.longEnough') }}
                  </div>
                  <div>
                    <v-icon>
                      {{ passwordNotOnWeakList ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                    {{ $t('reset_pw.diversity.notOnWeakList') }}
                  </div>
                </v-alert>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              class="py-0"
            >
              <v-text-field
                outlined
                dense
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                v-model="form.confirmPassword"
                :label="$t('reset_pw.form.confirm_pw')"
                :error="$v.form.confirmPassword.$error"
                :error-messages="$v.form.confirmPassword.$error ?
                  (!$v.form.confirmPassword.required ? $t('validate.required', { field: $t('reset_pw.form.confirm_pw') }) :
                    (!$v.form.confirmPassword.sameAsPassword ? $t('validate.sameAs', { field: $t('Password') }) : '')) : ''"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <div
                v-if="!$v.form.newPassword.notWeak"
                class="error--text"
              >
                {{ $t('reset_pw.strength.weakNotAllowed') }}
              </div>
              <v-btn
                color="white"
                class="ma-2"
                :to="{ name: 'Login' }"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-home
                </v-icon> {{ $t('reset_pw.buttons.back_login') }}
              </v-btn>
              <v-btn
                class="primary ma-2"
                type="submit"
                :loading="requestLoading"
                :disabled="!$v.form.newPassword.notWeak"
              >
                {{ $t('reset_pw.buttons.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <!-- <v-card-actions class="text-center">
                    </v-card-actions> -->
    </v-card>
    <loading
      :active.sync="requestLoading"
      :is-full-page="false"
    />
  </v-container>
  <!-- </v-main>
        <v-footer id="footer" class="blue--text">
            {{ $t('footer.copyright') }}
        </v-footer>
    </v-app> -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import Vuelidate from 'vuelidate'
import Vue from 'vue'

Vue.use(Vuelidate)

export default {
  watch: {
    is_logged_in () {
      this.redirectIfNeeded()
    }
  },
  computed: {
    ...mapGetters('auth', [
      'is_logged_in',
      'weakPasswords'
    ]),
    passwordAtLeastOneUpperCase () {
      return /[A-Z]/.test(this.form.newPassword)
    },
    passwordAtLeastOneLowerCase () {
      return /[a-z]/.test(this.form.newPassword)
    },
    passwordAtLeastOneNumber () {
      return /[0-9]/.test(this.form.newPassword)
    },
    passwordAtLeastOneSpecialCharacter () {
      return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.form.newPassword)
    },
    passwordLongEnough () {
      return this.form.newPassword.length >= 12
    },
    passwordNotOnWeakList () {
      return !this.weakPasswords.includes(this.form.newPassword)
    },
    passwordStrength () {
      const strength = {
        atLeastOneUpperCase: this.passwordAtLeastOneUpperCase ? 1 : 0,
        atLeastOneLowerCase: this.passwordAtLeastOneLowerCase ? 1 : 0,
        atLeastOneNumber: this.passwordAtLeastOneNumber ? 1 : 0,
        atLeastOneSpecialCharacter: this.passwordAtLeastOneSpecialCharacter ? 1 : 0,
        longEnough: this.passwordLongEnough ? 1 : 0
      }
      const total = Object.values(strength).reduce((a, b) => a + b)
      return total === 5 && this.passwordNotOnWeakList && this.form.newPassword.length >= 8 ? 'strong' : (total >= 3 && this.passwordNotOnWeakList && this.form.newPassword.length >= 8 ? 'medium' : 'weak')
    }
    // validateBothPassword() {
    //     return () => (this.form.newPassword === this.form.confirmPassword) || 'Password does not match'
    // }
  },
  data () {
    return {
      showNewPassword: false,
      showConfirmPassword: false,
      form: {
        token: '',
        newPassword: '',
        confirmPassword: ''
      },
      success: false,
      requestLoading: false,
      isTokenExpired: false
      // rules: {
      //     counter: value => value.length >= 6 || 'Minimum of 6 characters are required',
      //     // email: value => {
      //     //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //     //     return pattern.test(value) || 'Invalid e-mail.'
      // },
    }
  },
  validations () {
    return {
      form: {
        token: {
          required
        },
        newPassword: {
          required,
          minLength: minLength(8),
          notWeak: (password) => {
            if (!password) return true
            return this.passwordStrength !== 'weak'
          }
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs('newPassword')
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'login',
      'reset',
      'verifyResetPasswordToken'
    ]),
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.token.$invalid) {
        this.tokenVerification() // Otherwise will have no response after clicking 'CONFRIM' button
      }
      if (this.$v.form.$invalid) {
        return
      }
      this.requestLoading = true
      this.reset(this.form).then((unusedRes) => {
        this.requestLoading = false
        this.$notify({
          text: this.$t('reset_pw.subtitle.success'),
          type: 'success',
          duration: -1
        })
        this.success = true
      })
        .catch((res) => {
          this.requestLoading = false
          this.$notify({
            text: res == null ? this.$t('reset_pw.msg.error') : (res.reset_status === -2 ? this.$t('reset_pw.msg.expired') : this.$t('reset_pw.msg.incorrect_info')),
            type: 'error'
          })
        })
    },
    tokenVerification () {
      this.requestLoading = true
      this.verifyResetPasswordToken(this.form.token).then(() => {
        this.requestLoading = false
      })
        .catch((unusedError) => {
          this.requestLoading = false
          this.$notify({
            text: this.$t('reset_pw.msg.expired'),
            type: 'error'
          })
        })
    },
    redirectIfNeeded () {
      if (this.is_logged_in && this.$route.name !== 'Dashboard') {
        this.$router.replace({ name: 'Dashboard' })
      }
    }
  },
  mounted () {
    this.redirectIfNeeded()
    this.form.token = this.$route.query.token || ''
    this.tokenVerification()
  }
}
</script>

<style>
.v-application {background-image: radial-gradient(circle at 69% 75%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 38%,transparent 38%, transparent 69%,transparent 69%, transparent 100%),radial-gradient(circle at 41% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 3%,transparent 3%, transparent 75%,transparent 75%, transparent 100%),radial-gradient(circle at 94% 91%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 48%,transparent 48%, transparent 55%,transparent 55%, transparent 100%),radial-gradient(circle at 68% 38%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 34%,transparent 34%, transparent 36%,transparent 36%, transparent 100%),radial-gradient(circle at 81% 20%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 40%,transparent 40%, transparent 61%,transparent 61%, transparent 100%),radial-gradient(circle at 46% 37%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 37%,transparent 37%, transparent 76%,transparent 76%, transparent 100%),radial-gradient(circle at 49% 5%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 43%,transparent 43%, transparent 67%,transparent 67%, transparent 100%),radial-gradient(circle at 18% 58%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 4%,transparent 4%, transparent 20%,transparent 20%, transparent 100%),radial-gradient(circle at 43% 68%, hsla(65,0%,95%,0.05) 0%, hsla(65,0%,95%,0.05) 10%,transparent 10%, transparent 36%,transparent 36%, transparent 100%),linear-gradient(135deg, rgb(85, 133, 238),rgb(177, 145, 214));}
.v-footer {background-image: repeating-linear-gradient(45deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(0deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(135deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),repeating-linear-gradient(90deg, hsla(207,0%,63%,0.05) 0px, hsla(207,0%,63%,0.05) 1px,transparent 1px, transparent 11px,hsla(207,0%,63%,0.05) 11px, hsla(207,0%,63%,0.05) 12px,transparent 12px, transparent 32px),linear-gradient(90deg, rgb(17, 17, 17),rgb(66, 66, 66));}
</style>
